<mat-grid-list fxLayout="row" [cols]="columns" rowHeight="350px" gutterSize="10px" (window:resize)="onResize($event)">
    <mat-grid-tile *ngFor="let item of items">
        <mat-grid-tile-header>
            {{item.title}} - {{item.cost | currency}} 
        </mat-grid-tile-header>
        <img [src]="item.image">
       <mat-grid-tile-footer fxLayout="row">
        <span fxFlex="60"> {{item.onHand}} Left </span>
        <span fxFlex="40">  <button mat-button color="primary" (click)="addTocart(item)">Add to Order</button></span>
       </mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="items && items.length" [style.background]="'lightblue'">
        <button mat-raised-button class="submit-order-button"color="warn" [routerLink]="['/cart']">Place Order</button>
    </mat-grid-tile>
  </mat-grid-list>
