import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import 'firebase/firestore';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { CartItem } from '@countingfish/models';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  constructor(private firestore: AngularFirestore) { }

  getItems(): Observable<any[]> {

   const items = this.firestore.collection('items').snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data() as CartItem;
      const id = a.payload.doc.id;
      return { id, ...data };
    }))
  );

   return items;
  }
}
