<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span fxShow fxHide.sm fxHide.xs>
      Specialty coffee from the foothills of the Catskill mountains... old Klipnockie.
    </span>
    <mat-icon aria-hidden="false" aria-label="home"  fxLayout="row" fxHide fxShow.sm fxShow.xs routerLink="/">home</mat-icon>
    <span class="toolbar-spacer"></span>
    <mat-icon aria-hidden="false" aria-label="Shopping Cart">shopping_cart</mat-icon>
  </mat-toolbar-row>
</mat-toolbar> 
<section>
  <nav class="big-nav" fxLayout="row" fxLayoutAlign="start center" fxFlex fxHide fxShow.gt-sm>
    <span fxFlex="20">
      <img src="assets/klipnockie.png" routerLink="/">
    </span>
    <span fxFlex="40" class="sub-title">
        
    </span>
    <span fxFlex="10"></span>
    <span fxFlex="10"></span>
    <span fxFlex="10"></span>
    <span fxFlex="10">
      <!-- <h3>
        <a href="#" routerLink="/events">Contact</a>
      </h3> -->
     </span>
  </nav>

</section>

<div class="app-container">
  <router-outlet></router-outlet>
</div>