import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

import { CartItem } from '@countingfish/models';

import { ItemsService } from './items.service';

@Component({
  selector: 'countingfish-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss'],
})
export class ItemsComponent implements OnInit {
  items: CartItem[];
  columns: number;

  constructor(
    private itemsService: ItemsService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.setLayout();
    this.getItems();
  }

  onResize(event: Event) {
    this.setLayout();
  }

  addTocart(item: CartItem): void {
  //  this.cartService.addItem(item);
  }

  private getItems(): void {
    const items = this.itemsService.getItems();
    items.subscribe(i => (this.items = i));
  }

  private setLayout(): void {
    this.columns = 5;
    const isSmallScreen = this.breakpointObserver.isMatched(
      '(max-width: 599px)'
    );
    const isMediumScreen = this.breakpointObserver.isMatched(
      '(max-width: 959px)'
    );
    if (isMediumScreen && !isSmallScreen) {
      this.columns = 2;
    } else if (isMediumScreen && isSmallScreen) {
      this.columns = 1;
    }
  }

}
